import { Component } from '@angular/core';
import { PhotosService } from './services/photos-service/photos.service';
import {Howl} from 'howler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  sounds = ['assets/sounds/meow1.mp3', 'assets/sounds/meow2.mp3', 'assets/sounds/meow3.mp3', 'assets/sounds/meow4.mp3'];

  constructor(private photosService: PhotosService) {
    this.getCat();
  }

  imgUrl: string = '';

  getCat() {
    this.photosService.getRandomPhoto().subscribe(
      data => {
        const index = Math.floor(Math.random() * this.sounds.length);

        let sound = new Howl({
          src: [this.sounds[index]],
        });

        sound.play();

        this.imgUrl = data[0].url;
      },
      error => {}
    );
  }

}
