<div class="container mt-5 mb-5">
  <div class="card">
    <div class="card-body text-center">
      <h1 class="card-title ">Random Cat Images</h1>
      <div class="divider"><hr></div>
      <a *ngIf="imgUrl" class="mt-3" href="{{ imgUrl }}" target="_blank"><img class="img-fluid" src="{{ imgUrl }}"></a><br>
      <button (click)="getCat()" class="btn btn-success mt-4"><strong>Get Random Cat !</strong></button>
    </div>
  </div>
</div>
