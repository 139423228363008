import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class PhotosService {

  headers = {
    headers: {
      'x-api-key': '192735c2-040d-4ab9-97e7-fa8bbf2f0a9e'
    }
  };

  constructor(private http: HttpClient) { }

  getRandomPhoto() {

    return this.http.get('https://api.thecatapi.com/v1/images/search', this.headers);

  }

}
